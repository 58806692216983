import "alpinejs";

console.log("Tanja Nürnberger - Telefon 08862 911568");

window.scrollToReveal = function () {
  return {
    physio: false,
    manuelle: false, 
    massage: false, 
    sticky: false,
    lastPos: window.scrollY,
    scroll() {
  
        let scrollEnough = Math.abs(this.lastPos - window.scrollY);
        // console.log(scrollEnough)
        if (scrollEnough > 6) {
        //   let upScroll = window.scrollY > this.$refs.navbar.offsetHeight && this.lastPos > window.scrollY;
        let upScroll = window.scrollY > this.lastPos;
 
          let scrollMin = window.scrollY >  document.getElementById("leistungen").offsetTop
        //   console.log(upScroll)
          if (!scrollMin && !upScroll) 
           {
            
  
           // console.log("Minbereich und Upscroll")
        
          } else if (!scrollMin && upScroll) { 
            // console.log("Minbereich und Downscroll")
            this.sticky = !upScroll
           
          } else  { 
           // console.log("Der Rest")
            this.sticky = !upScroll
          }

          // console.log(  " window.scrollY " + window.scrollY + " this.lastPos " + this.lastPos )
        }

        // } else {  this.sticky = false}
        this.lastPos = window.scrollY;
 
    }
  };
};


// Alpine 2.3.5
window.testimonialSlideshow = function(slides) {
	return {
		title: 'Kundenstimmen',
		state: {
			moving: false,
			currentSlide: 0,
			looping: true,
			order: [],
			nextSlideDirection: '',
			userInteracted: false,
			usedKeyboard: true,
		},
		autoplayTimer: null,
		attributes: {
			direction: 'right-left',
			duration: 1000,
			timer: 14000,
		},
		slides: [],
		setup() {
			this.slides = slides.map((slide, index) => { slide.id = index + Date.now(); return slide })
				
			// Cache the original order so that we can reorder on transition (to skip inbetween slides)
			this.state.order = this.slides.map(slide => slide.id)
			const newSlideOrder = this.slides.filter(slide => this.current.id != slide.id)
			newSlideOrder.unshift(this.current)
			this.slides = newSlideOrder
				
			// Start the autoslide
			this.attributes.timer && this.autoPlay()
		},
		get current() {
			return this.slides.find(slide => slide.id == this.state.order[this.state.currentSlide])
		},
		get previousSlide() {
			return (this.state.currentSlide - 1) > -1 ? this.state.currentSlide - 1 : this.state.currentSlide
		},
		get nextSlide() {
			return (this.state.currentSlide + 1) < this.slides.length ? this.state.currentSlide + 1 : this.state.currentSlide
		},
		updateCurrent(nextSlide) {
			if (nextSlide == this.state.currentSlide) return
			if (this.state.moving) return
			this.state.moving = true

			const next = this.slides.find(slide => slide.id == this.state.order[nextSlide])

			// Reorder the slides for a smoother transition
			const newSlideOrder = this.slides.filter(slide => {
				return ![this.current.id, this.state.order[nextSlide]].includes(slide.id)
			})

			const activeSlides = [this.current, next]
			this.state.nextSlideDirection = nextSlide > this.state.currentSlide ? 'right-to-left' : 'left-to-right'

			newSlideOrder.unshift(...(this.state.nextSlideDirection == 'right-to-left' ? activeSlides : activeSlides.reverse()))
			this.slides = newSlideOrder
			this.state.currentSlide = nextSlide
			setTimeout(() => {
				this.state.moving = false
				// TODO: possibly a better check to determine whether autoplay should resume
				this.attributes.timer && !this.autoplayTimer && this.autoPlay()
			}, this.attributes.duration)

		},
		transitions(state, $dispatch) {
			const rightToLeft = this.state.nextSlideDirection === 'right-to-left'
			switch (state) {
				case 'enter':
					return `transition-all duration-${this.attributes.duration}`
				case 'enter-start':
					return rightToLeft ? 'transform translate-x-full' : 'transform -translate-x-full'
				case 'enter-end':
					return 'transform translate-x-0'
				case 'leave':
					return `absolute top-0 transition-all duration-${this.attributes.duration}`
				case 'leave-start':
					return 'transform translate-x-0'
				case 'leave-end':
					return rightToLeft ? 'transform -translate-x-full' : 'transform translate-x-full'
			}
		},
		autoPlay() {
			this.loop = () => {
				const next = (this.state.currentSlide === (this.slides.length - 1)) ? 0 : this.state.currentSlide + 1
				this.updateCurrent(this.state.looping ? next : this.currentSlide)
				this.autoplayTimer = setTimeout(() => {
					requestAnimationFrame(this.loop)
				}, this.attributes.timer + this.attributes.duration)
				
			}
			this.autoplayTimer = setTimeout(() => {
				this.state.looping = true
				requestAnimationFrame(this.loop)
			}, this.attributes.timer)
		},
		stopAutoplay() {
			clearTimeout(this.autoplayTimer)
			this.autoplayTimer = null
		}
	}
}

window.slides = [
	{
		content: ' Liebe Tanja! Es geht mir heute und auch gestern schon nach deiner Behandlung so viel besser. Du hast wirklich eine große Begabung. Es ist für mich eine richtig gute Erfahrung. Ich habe gestern schon die Stunden innerlich gezählt, wann ich zu dir kann.  Du machst das richtig gut und ich hab noch niemanden erlebt, der das so gut macht wie du. Und ich bin schon rumgekommen, Danke.     Ich bin echt dankbar.',
    author: 'Claudia H.E.'
	},
	{
		content: 'Bei Tanja Nürnberger steht der Patient mit seinen individuellen Bedürfnissen ganz klar im Vordergrund. Keine Behandlung von der Stange, sondern individuelle Lösungen aus ihrem reichhaltigen physiotherapeutischem Repertoire. Dazu kommt noch ihre ruhige und aufmerksame Ausstrahlung und nicht zu vergessen die schönen Räumlichkeiten. Beste Voraussetzungen für eine entspannte Heilung.',
		author: 'Markus W.',
	}
]